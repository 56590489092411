import './App.css';
import React, { useEffect } from "react";
import Header from './components/header';
import Dashboard from './components/dashboard';
import Subbar from './components/subbar';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import Shutters from './components/products/shutters';
import Aboutus from './components/products/aboutus'
import ContactUs from './components/contactus';
import Gallery from './components/products/gallery';
import Aos from "aos";
import AutomaticMotor from './components/products/automatic_motor';
import PolycarbonateShutter from './components/products/polycarbonate_shutter';
import ShutterAccessories from './components/products/shutter_accessories';
import "aos/dist/aos.css"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Footer from './components/footer';

const App = ()=> {
  useEffect(()=>{
    Aos.init({duation:2000});
        },[]);
  return (
    
    <Router>
      <div className="App">
   
          <Header/>
          <MessengerCustomerChat
            pageId='109100608018465'
            language='en_US'
        />
          <Subbar/>
            <Switch>
              <Route exact path="/">
                <Dashboard/>
              </Route>
              <Route exact path="/shutters">
                <Shutters/>
              </Route>
              <Route exact path="/aboutus">
                <Aboutus/>
              </Route>
              <Route exact path="/contactus">
                <ContactUs/>
              </Route>
              <Route exact path="/gallery">
                <Gallery/>
              </Route>
              <Route path="/polycarbonateshutter">
                <PolycarbonateShutter/>
              </Route>
              <Route path="/accessories">
                <ShutterAccessories/>
              </Route>
              <Route path="/automaticmotors">
                <AutomaticMotor/>
              </Route>
            </Switch>
          <Footer/>
        </div>
    </Router>
  );
}

export default App;
