import React from "react";

const PolycarbonateShutter = () => {
    return (
        <div class="main_container">
            <div className = "title_container" style={{backgroundImage:'url("resources/carousel_1.jpg")'}}>
                    <div className="title_text_container">
                             <p>Polycarbonate Shutters</p>
                    </div>
            </div>
        <div className="container pt-3">
            <div id="polycarbonate" className="row mb-2 withScroll" >
                <div className="col-lg-6 col-12">
                    <img className=" myCard myImage rounded" src="resources/shutters/polycarbonate.jpg"/>
                </div>
                <div className="col-lg-6 col-12 p-3" data-aos="fade-left">
                    <h3 className="col-12 text-left pl-0" > Polycarbonate Shutter</h3>
                    <p className="lead text-left" style={{fontSize:"1rem"}}>
                    <span  style={{textAlign:'justify'}}>
                    They are highly elegant and employ the indubitable style. 
                    Made up of best or high-class materials to provide better results. 
                    Transparent security shutters are suitable for those shopping centres that are situated in cities or the hub of the megacity i.e. towns. 
                    As its name suggests transparent, it is used to delivers a higher level of security and performance. 
                    Best suited example for malls that usually deal with high-class items or other outlets like supermarkets, museums, and banks, etc.
                    </span>
                    <br/>
                    <br/>
                    <span>
                        Our Polycarbonate Shutters are almost completely transparent and allow people to look into a shop outside opening hours. 
                        They are very strong so if security is also a concern, they are a good choice.
                    </span>
                    <br/>
                    <br/>
                    <strong style={{fontWeight:'bold'}}>
                        Security roller shutters with a sense of openness
                    </strong>
                    <br/>
                    <br/>
                    Malls and shopping centres commonly have CCTV installed and employ security personnel. Therefore, for shopping mall roller shutters, security isn't just the only concern. Instead, aesthetics play as much as important role. We have a range of shutters that work beautifully in shopping centres. 
                    Most shopping centres have wide corridors, high ceilings and light interiors. This creates a sense of spaciousness and openness. We supply various security shutters that beautifully supplement these qualities.

                    </p>
                </div>
            </div>
            <hr/>
            <div id="longpoly" className="row withScroll">
                <div className="col-lg-12 col-12 p-3" data-aos="fade-up">
                    <h3 className="col-12 text-center pl-0"  >Long Polycarbonate Slats</h3>
                    <p className="lead text-left" style={{fontSize:"1rem"}}>
                        <span>
                        Imported raw materials make sure what you received will be environmental-friendly, long-life, and 92% light transmittance.
Exterior film will protect the slats in perfect condition during transportation and  installation
                        </span>
                    </p>
                </div>
                <div className="col-lg-4 col-12 mb-2">
                    <img className=" myCard myImage rounded" src="resources/lps1.jpg"/>
                </div>
                <div className="col-lg-4 col-12 mb-2">
                    <img className=" myCard myImage rounded" src="resources/lps2.jpg"/>
                </div>
                <div className="col-lg-4 col-12 mb-2">
                    <img className=" myCard myImage rounded" src="resources/lps3.jpg"/>
                </div>
            </div>
            <hr></hr>
            <div className="row">
                <div id="alum_frame_strong" className="col-lg-8 col-12 p-3" data-aos="fade-right">
                    <h3 className="col-12 text-left pl-0"  >Aluminum Connector</h3>
                    <p className="lead text-left" style={{fontSize:"1rem"}}>
                        <span>
                        Aluminium provides an advantageous, high grade material that guarantees durability and longer product life. It also retains its shape and colour.
                        The Aluminum Single aluminum frame is suitable for small roller shutter door, within 6m wide x 8m high roll up door.
                        </span>
                    </p>
                </div>
                <div className="col-lg-4 col-12 mb-2">
                    <img className=" myCard  rounded" src="resources/aluminumconnector.jpg"/>
                </div>
            </div>
            <hr/>
            <div id="aluminum_bottombar" className="row mb-2 withScroll">
                <div className="col-lg-4 col-12">
                <img className=" myCard  rounded" src="resources/bottombar.jpg"/>
                </div>
                <div className="col-lg-8 col-12 p-3" data-aos="fade-left">
                <h3 className="col-12 text-center pl-0">Aluminum Bottombar</h3>
                    <p className="lead text-left" style={{fontSize:"1rem"}}>
                    <span>
                    Aluminium provides an advantageous, high grade material that guarantees durability and longer product life. 
                    </span>
                  
                    <span>
                    It also retains its shape and colour.Single aluminum frame is suitable for small roller shutter door, within 6m wide x 8m high roll up door.
                    </span>
                    <br/>
                
                    </p>
                </div>
                   
            </div>
            <hr/>
          
            <div id="plastic_endcap" className="row withScroll" >
                <div className="col-lg-8 col-12 p-3" data-aos="fade-right">
                    <h3 className="col-12 text-left pl-0"  >Plastic Endcap</h3>
                    <p className="lead text-left" style={{fontSize:"1rem"}}>
                        <span>
                        The Plastic Endcap plays a very important role to keep the Polycarbonate Slats in tact with the Aluminum Frame. This is placed at the ends of the Aluminum Frame once the Polycarbonate Slats are well inserted.
                        </span>
                    </p>
                </div>
                <div className="col-lg-4 col-12 mb-2">
                    <img className=" myCard rounded" src="resources/plasticendcap.png" alt="Plastic EndCap Image"/>
                </div>
            </div>
            <hr/>
            <div id="rubber_strip" className="row mb-2 withScroll">
                <div className="col-lg-6 col-12">
                <img className=" myCard myImage rounded" src="resources/rubberstrip.jpg"/>
                </div>
                <div className="col-lg-6 col-12 p-3" data-aos="fade-left">
                <h3 className="col-12 text-center pl-0">Rubber Strip</h3>
                    <p className="lead text-left" style={{fontSize:"1rem"}}>
                    Rubber Strips attached to the Aluminum Bottombar makes sure that the shutters would close without noise and scratching. This feature is very important for shutters used in offices, shopping malls and other commercial areas. Our rubber strip can also be used in other aluminum profiles such as window and door assembly.
                    <span>
                  
                    </span>
                    <br/>
                
                    </p>
                </div>
                   
            </div>
            <hr/>
          
        </div>
        </div>
    )
}

export default PolycarbonateShutter;