import React from 'react';
import './index.css';
import Swal from 'sweetalert2';
const ContactUs = ()=> {
    const submitForm = (e)=> {
        e.preventDefault();
        Swal.fire('Thank you!','We have received your message. Thank you for your feedback/concerns',"info");
    }
    return (
<div className="row m-1 pl-3 pr-3 pb-5" id="contatti" data-aos='zoom-in'>
<div className="container mt-5" >

    <div className="row" style={{boxShadow: '0 4px 21px -12px rgba(0, 0, 0, 0.66)',borderRadius: '10px'}}>
      <div className="col-md-6 maps" style={{padding:'2%'}} >
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d573.7969640230285!2d120.9958181402628!3d14.654211234319494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b6868a59210b%3A0x947c86271b4f1f6!2s138%20DPHP%20Compound%201%2C%20East%20Grace%20Park%2C%20Caloocan%2C%20Metro%20Manila!5e0!3m2!1sen!2sph!4v1635230431132!5m2!1sen!2sph" frameborder="0" style={{border:"0"}} allowfullscreen></iframe>
         
      </div>
      <div className="col-md-6">
        <h2 className="text-uppercase mt-3 font-weight-400" style={{color:'#243A84'}}>CONTACT US</h2>
        <form onSubmit={submitForm}>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <input type="text" className="form-control mt-2" placeholder="Name" required/>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <input type="text" className="form-control mt-2" placeholder="Subject" required/>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <input type="email" className="form-control mt-2" placeholder="Email" required/>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <input type="number" className="form-control mt-2" placeholder="Telephone" required/>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Insert text" rows="3" required></textarea>
              </div>
            </div>
            <div className="col-12">
            </div>
            <div className="col-12">
              <button className="btn btn-primary" style={{backgroundColor:'#243A84'}}type="submit">Submit</button>
            </div>
          </div>
        </form>
        <div className="text-black">
        <h2 className="text-uppercase mt-4 font-weight-400" style={{color:'#243A84'}} > Where we are </h2>

        <i className="fas fa-phone mt-3"></i> <a href="tel:+">(+63) 966 633 5605</a><br/>
        <i className="fas fa-globe mt-3"></i> Caloocan City - South, Metro Manila<br/>
        <div className="my-4">
        <a href="https://www.facebook.com/interclovetrading"><i className="fab fa-facebook fa-3x pr-4"></i></a>
        </div>
        </div>
      </div>

    </div>
</div>
</div>
    )
}

export default ContactUs;