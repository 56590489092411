import React from "react";


const Aboutus = () => {

    return (
     
      
		<div class="wrapper" style={{fontFamily:"'Poppins', sans-serif"}}>
  <div class="profile-card js-profile-card" data-aos="zoom-out">
    <div class="profile-card__img">
      <img src="resources/interclove_logo.png" alt="profile card"></img>
    </div>
      <div class="profile-card__name">Interclove Company</div>
    <p><span><i class="fas fa-map-marker-alt"></i></span> <small style={{color:'#4D5761'}}>138 DPHP Compound, 11th Avenue, Balintawak, Caloocan City</small></p>

        <span class="">

			<p style={{textAlign:'justify',paddingLeft:'30px',paddingRight:'30px',textIndent:'50px'}}>
		Our company’s purpose is to constantly provide a smarter and more secure set of products that will improve the overall operations and security in shutter and door technology. Our aim is to be able to provide convenience, aesthetics and security to our clients. 	
		Interclove Co. has been in the industry of providing high quality polycarbonate shutters and roll up motor products and accessories that are mostly used in shopping malls, retail stores, and commercial banks since 2017. Since then, our company has not stopped looking for innovative products that can not only meet, but even surpass the requirements of our clients.
        </p>
		</span>	
     </div>
 </div>
    )
}

export default Aboutus;