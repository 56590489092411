import React from "react";

const ShutterAccessories = () => {
    return (
        <div class="main_container">
            <div className = "title_container" style={{backgroundImage:'url("resources/carousel_1.jpg")'}}>
                    <div className="title_text_container">
                             <p>Shutters Accessories</p>
                    </div>
            </div>
        <div className="container pt-3">
            <div className="content_container">
                <div className="materials_container px-5">
                <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12">
                            <div className="material_item">
                            <div class="card">
                            <div class="card-header">
                                        End Lock
                                    </div>
                                        <img class="center_item" style={{height:'35vh',width:'35vh'}} src="resources/endlock.png" alt="Card image"></img>
                                    </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                        Center Lock
                                    </div>
                                        <img class="center_item" style={{height:'35vh',width:'35vh'}} src="resources/centerlock.jpg" alt="Card image"></img>
                                    </div>
                      </div>
                    </div>
                     {/*end row*/}      
                     <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12">
                            <div className="material_item">
                            <div class="card">
                            <div class="card-header">
                                        Foot Bolt
                                    </div>
                                        <img class="center_item" style={{height:'35vh',width:'35vh'}} src="resources/footbolt.jpg" alt="Card image"></img>
                                    </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                        <div class="card">
                            <div class="card-header">
                                        Chain
                                    </div>
                                        <img class="center_item" style={{height:'35vh',width:'35vh'}} src="resources/chain.jpg" alt="chain"></img>
                                    </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-6 col-sm-12 mb-2">
                            <div className="material_item">
                            <div class="card">
                                    <div class="card-header">
                                        Spring
                                    </div>
                                        <img class="center_item" style={{height:'35vh',width:'35vh'}} src="resources/spring.png" alt="Card image"></img>
                                         <a href =""  data-toggle="modal" data-target="#moreDetails">View Sizes</a>
                                    </div>
                                  
                                  
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mb-2">
                      
                            <div className="material_item">
                            <div class="card">
                            <div class="card-header">
                                        Winding Wheel
                                    </div>
                                        <img class="center_item" style={{height:'35vh',width:'35vh'}} src="resources/windingwheel.png" alt="Card image"></img>
                                       <a href =""  data-toggle="modal" data-target="#moreDetails2">View Sizes</a>
                                    </div>
                                  
                                   
                            </div>
                        </div>
               
                    </div>

                </div>
            </div>
            <div class="modal fade" id="moreDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                        
                        <div class="modal-body">
                        <span class="text-left">
                         Material - 82b steel wire (best steel wire for spring)
                        <p> Made from the best steel wire known for spring. Our spring assures quality and long lasting performance. Our sizes are custom made to assure appropriate wire thickness - diameter ratio for each spring length.</p>
                         <ul style={{listStyleType:'none'}}>Sizes 
                          
                               <li> - 5.0mm x 80mm x 10”</li>
                               <li> - 5.0mm x 80mm x 12”</li>
                               <li> - 5.0mm x 80mm x 14”</li>
                               <li> - 5.5mm x 85mm x 16”</li>
                               <li> - 5.5mm x 85mm x 18”</li>
                               <li> - 5.5mm x 85mm x 20”</li>
                               <li> - 6.0mm x 90mm x 22”</li>
                               <li> - 6.0mm x 90mm x 24”</li>                           
                             </ul>
                    </span>
                        </div>
                      
                        </div>
                        
                    </div>
                    </div>
                    <div class="modal fade" id="moreDetails2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                        
                        <div class="modal-body">
                        <span class="text-left">
                     
                         <ul style={{listStyleType:'none'}}>Sizes 
                          
                               <li> - 75cm</li>
                               <li> - 85cm</li>
                                                      
                             </ul>
                    </span>
                        </div>
                      
                        </div>
                        
                    </div>
                    </div>
       
        </div>
        </div>
    )
}

export default ShutterAccessories;