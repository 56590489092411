import React from 'react';
import {useHistory} from 'react-router-dom'
const Subbar = ()=> {
    const history = useHistory();
    return (

        
        <div className=" w-100 row-nav pr-5 pl-5 pb-1 border-bottom pt-1" style={{position:'sticky',top:'0',zIndex:'100'}}>
                <div className="col-3">
                    <div class="box-1">
                        <div class="btn btn-one align-middle" onClick={()=> history.push('/')}>
                        <span>Home</span>   
                        </div>
                    </div>
                </div>
           
                <div className="col-3">
                <div class="dropdown w-100">
                 <div class="box-1">
                        <div class="btn btn-one dropbtn" >
                            <span>Products</span>
                        </div>
                        <div class="dropdown-content " style={{textAlign:'left',width:'50vw'}}>
                        <a onClick={()=> history.push('/polycarbonateshutter')} class="dropdownClassBg" >POLYCARBONATE SHUTTERS</a>
                        <a onClick={()=> history.push('/accessories')} class="dropdownClassBg" >SHUTTERS ACCESSORIES</a>
                        <a onClick={()=> history.push('/automaticmotors')} class="dropdownClassBg" >SHUTTER AUTOMATIC MOTOR</a>
                      
                    </div>
                    </div>
                </div>
            </div>
                <div className="col-3">
                 <div class="box-1">
                    <div class="btn btn-one" onClick={()=> history.push('/gallery')}>
                        <span>Gallery</span>
                    </div>
                    </div>
                </div>
                <div className="col-3">
                <div class="box-1">
                    <div class="btn btn-one"  onClick={()=> history.push('/aboutus')}>
                        <span>About us</span>
                    </div>
                    </div>
                </div>
                
               
            


        </div>
    

    )
}   

export default Subbar;