import React,{useState} from "react";
import ImageViewer from 'react-simple-image-viewer';

const Gallery = () => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
      'resources/products/Gallery1.jpg',
     
      'resources/products/Gallery3.jpg'
     
    ];
    const openImageViewer = (index) => {
      setCurrentImage(index);
      setIsViewerOpen(true);
    };
  
    const closeImageViewer = () => {
      setCurrentImage(0);
      setIsViewerOpen(false);
    };
    return (
        
    <div>
      <div class="row p-2">
      {
            images.map((src,index) => (
                  <div class="col-lg-4 col-md-6 col-sm-12 mb-3 mt-2">
                        <div class="card cardGallery myShadow ">
                              <img class="card-img-top" src={src} alt="Card image cap" key={index} onClick={() => openImageViewer(index)}></img>
                        </div>
                  </div>
            ))
      }

      </div>
    {isViewerOpen && (
        <ImageViewer
          src={ images }
          currentIndex={ currentImage }
          disableScroll={ false }
          onClose={ closeImageViewer }
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)"
          }}
          closeOnClickOutside={true}
        />
      )}
 

    </div>  
    )
    
}


export default Gallery;