import React from "react";
import {useHistory} from 'react-router-dom'

const Dashboard = () => {
	const history = useHistory();
    return (
     
        
<div class="">
<section>

<div id = "welomeBG" className="row m-0 align-items-center justify-content-center" style={{backgroundImage:'url("resources/shutter.jpg")'}}>
		
					<div className="align-middle ">
						<p class="welcome" >WELCOME TO INTERCLOVE</p>
					  <p class="tagline animated bounceIn ease-in-quad btn-shine" >Safety - Elegance - Convenience</p>
						</div>
		
</div>


<div class="light pt-5" >
	<div class="container py-1" >
		<article class="postcard light blue" data-aos="fade-left">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img" src="resources/shutters/normalshutter.jpg" alt="Image Title" />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title blue"><a href="#">Polycarbonate Shutters</a></h1>
				<div class="postcard__subtitle small">
					
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">Polycarbonate Shutters: They are highly elegant and employ the indubitable style. Made up of best or high-class materials to provide better results. Transparent security shutters are suitable for those shopping centres that are situated in cities or the hub of the megacity i.e. towns. As its name suggests transparent, it is used to delivers a higher level of security and performance. Best suited example for malls that usually deal with high-class items or other outlets like supermarkets, museums, and banks, etc.</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="fas fa-eye mr-2"></i><a href="#polycarbonate" onClick={()=> history.push('/polycarbonateshutter')}>See more...</a></li>			
				</ul>
			</div>
		</article>
		<article class="postcard light red" data-aos="fade-right">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img" src="resources/shutteracc.jpg" alt="Image Title" />	
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title red"><a href="#">Shutters Accessories</a></h1>
				<div class="postcard__subtitle small">
				
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">	Shutter Accessories includes End Lock, Center Lock, Foot Bolt, Chain, Spring and Winding Wheel
</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="fas fa-eye mr-2"></i><a href="#longpoly" onClick={()=> history.push('/accessories')}>See more...</a>  </li>
					
				</ul>
			</div>
		</article>
		<article class="postcard light green" data-aos="fade-left">
			<a class="postcard__img_link" href="#">
				<img class="postcard__img" src="resources/macgear.png" alt="Image Title" />
			</a>
			<div class="postcard__text t-dark">
				<h1 class="postcard__title green"><a href="#">Shutter Automatic Motor</a></h1>
				<div class="postcard__subtitle small">
					
				</div>
				<div class="postcard__bar"></div>
				<div class="postcard__preview-txt">MACGEAR roll up motor manufacturer has an independent research and development department that has a number of highly qualified engineers and strong motor assembly team. Our motors have the features of low noise, big torques, quality and durability.
</div>
				<ul class="postcard__tagbox">
					<li class="tag__item"><i class="fas fa-eye mr-2"></i><a href="#alum_frame_strong" onClick={()=> history.push('/automaticmotors')}>See more...</a> </li>
				
				</ul>
			</div>
		</article>
		
	</div>
</div>
</section>
     


</div>

    )
}


export default Dashboard;