import React from 'react';
import {useHistory} from 'react-router-dom'
const Header = ()=> {
    const history = useHistory();
    return (
        
<div>
  <section class="sticky-top">
        <nav className="navbar navbar-expand-lg justify-content-between">
            <a href="/" className="navbar-brand">
                <img class="logo" src="resources/interclove_logo.png"/>
                <img class="text_logo" src="resources/inteclove_text.png"/>
            </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <li class="nav-item dropbtn dropdown text-center hoveran">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" >Products</a>
                        <div class="dropdown-menu">
                            <a href="#" onClick={()=> history.push('/polycarbonateshutter')} class="dropdown-item text-center hoveran" >POLYCARBONATE SHUTTERS</a>
                            <a href="#" onClick={()=> history.push('/accessories')} class="dropdown-item text-center hoveran" >SHUTTERS ACCESSORIES</a>
                            <a href="#" onClick={()=> history.push('/automaticmotors')} class="dropdown-item text-center hoveran" >SHUTTER AUTOMATIC MOTOR</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#" onClick={()=> history.push('/gallery')}>Gallery</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="javascript:void(0)" onClick={() => history.push('/contactus')}>Contact Us</a>
                    </li>
                    <li className="nav-item view_products" >
                        <a className="nav-link" href="javascript:void(0)" onClick={()=> history.push('/aboutus')}>About Us</a>
                    </li>
                    <li className="nav-item socmed">
                        <a className="nav-link" href="https://www.facebook.com/interclovetrading/" target="_blank"><i class="fab fa-facebook-square socmed"></i></a>  
                    </li>
                    <li className="nav-item socmed">
                        <a className="nav-link" href="#"><i class="fab fa-instagram socmed"></i></a>  
                    </li>
                    {/* <li className="nav-item socmed">
                        <a className="nav-link" href="#"><i class="fab fa-facebook-messenger socmed"></i></a>  
                    </li> */}
               
                </ul>
            </div>
            </nav>
</section>
<section class="sticky-top">
        
     
                   
       </section>
       </div>
    )
}   

export default Header;