import React from "react";

const AutomaticMotor = () => {
    return (
        <div class="main_container">
            <div className = "title_container" style={{backgroundImage:'url("resources/carousel_1.jpg")'}}>
                    <div className="title_text_container">
                             <p>Automatic Motor</p>
                    </div>
            </div>
        <div className="container pt-3">
            <div className="content_container">
                <div className="materials_container">
                        <div className="row mb-4">
                            <div class="col-lg-6 col-sm-12">
                                <img className=" myCard myImage rounded" src="resources/macgear.png" style={{objectFit:'contain'}}/>
                            </div>
                            <div class="col-lg-6 col-sm-12">
                            <h3 className="text-left pl-0"  >MACGEAR Roll Up Motor</h3>
                                <p className="lead text-left" style={{fontSize:"1rem"}}>
                                    <span>
                                    MACGEAR roll up motor manufacturer has an independent research and development department that has a number of highly qualified engineers and strong motor assembly team. Our motors have the features of low noise, big torques, quality and durability
                                    </span> <br/><br/>
                                    <ol>  <strong style={{fontWeight:'bold'}}>Product Characrteristics:</strong><br/>
                                <li> 	Complete set includes the motor, side plate, push buttons with locked case, remote and receiver.</li>
                                    <li>	Equipped with double brake system, ensures reliability and safe braking, effectively prevents fast fall. The 2nd braking ensures the stability of braking, manual drawing chains is less than 10mm.</li>
                                    <li>	The installation of mainframe and side plate is convenient, saves time and labor.</li>
                                    <li>	The maximum distance can reach more than 10 meters. Position limit is precise, as it is easy for allocation.</li>
                                    <li>	Could still be operated in cases of power failure.</li>
                                    <li>	The turning parts adopt high quality steel that is processed with tempering for high strength and durability.</li>
                                    <li>	The motor’s starting movement is big, running noise is low, and has an automatic reset heat protection device which ensures that the motor is safe.</li>
                                    <li>	Volume is small and the horsepower is strong. <a href =""  data-toggle="modal" data-target="#moreDetails">View Details</a></li>
                                </ol>
                                </p>
                                        </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-12">
                                    <img className=" myCard myImage rounded" src="resources/shutterautomaticmotor.jpg" style={{objectFit:'contain'}}/>
                            </div>
                        </div>
                </div>
            </div>
            <div class="modal fade" id="moreDetails" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                        
                        <div class="modal-body">
                        <img className=" myCard myImage rounded" src="resources/macgearDetails.png" style={{objectFit:'contain'}}/>
                        </div>
                      
                        </div>
                    </div>
                    </div> 
        </div>
        </div>
    )
}

export default AutomaticMotor;