import React from 'react';
import {useHistory} from 'react-router-dom'
const Footer = ()=> {
    const history = useHistory();
    return (

        <section>

<footer class="page-footer font-small cyan darken-3" style={{backgroundColor:'#EDEBF2'}} >

  <div class="container">

    <div class="row pt-3">


      <div class="col-md-12 py-2" >
        <div class="mb-1 flex-center">

  
          <a href = "https://www.facebook.com/interclovetrading/" target="_blank" class="fb-ic">
            <i class="fab fa-facebook-f fa-lg white-text mr-md-5 mr-3 ml-md-5 ml-3 fa-2x"> </i>
          </a>
          <a class="ins-ic">
            <i class="fab fa-instagram fa-lg white-text mr-md-5 mr-3 ml-md-5 ml-3 fa-2x"> </i>
          </a>
          {/* <a class="pin-ic">
            <i class="fab fa-facebook-messenger fa-lg white-text fa-2x"> </i>
          </a> */}
        </div>
      </div>


    </div>


  </div>

  <div class="footer-copyright text-center py-3">Since 2019.
    <a href=""> Interclove Co.</a>
  </div>


</footer>

</section>
     
                
               
            

      
    

    )
}   

export default Footer;